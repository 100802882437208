import React from "react"
import styled from "styled-components"


const AboutContainer = styled.div`
  padding: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 320px) {
    padding: 1em;
  }
  @media screen and (min-width: 425px) {
    padding: 1em;
  }
`
const AboutWrapper = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
  width: 31%;
  padding: 48px;
  @media screen and (min-width: 320px) {
    width: 100%;
    padding: 0;
    border: none;
    border-radius: 0;
    background: none;
  }
  @media screen and (min-width: 425px) {
    width: 100%;
    border: none;
    border-radius: 0;
    background: none;
  }
  @media screen and (min-width: 768px) {
    width: 80%;
    border: 2px solid #1d9683;
    border-radius: 15em 0 15em 0;
    background-color: #73747316;
  }
  @media screen and (min-width: 1024px) {
    width: 70%;
    border: 2px solid #1d9683;
    border-radius: 15em 0 15em 0;
    background-color: #73747316;
  }
  @media screen and (min-width: 1440px) {
    width: 60%;
    border: 2px solid #1d9683;
    border-radius: 15em 0 15em 0;
    background-color: #73747316;
  }
`
const Hr = styled.hr`
  background-color: #1d9683;
  border: none;
  height: 1px;
  width: 80%;
  @media screen and (min-width: 768px) {
    width: 20%;
  }
  @media screen and (min-width: 1024px) {
    width: 60%;
  }
  @media screen and (min-width: 1440px) {
    height: 2.5px;
    width: 60%;
  }
`
const Header = styled.h1`
  font-family: Oswald;
  font-style: normal;
  font-weight: 400;
  font-size: 3.5em;
  line-height: 1.5em;
  color: #1d9683;
  @media screen and (min-width: 320px) {
    font-size: 2.5em;
  }
  @media screen and (min-width: 425px) {
    font-size: 2.5em;
  }
  @media screen and (min-width: 768px) {
    font-size: 2.5em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 2.5em;
  }
  @media screen and (min-width: 1440px) {
    font-size: 3em;
  }
`

const Text = styled.p`
  font-family: Oswald;
  font-size: 2.3em;
  font-weight: 300;
  line-height: 1.5em;
  text-align: center;
  width: 900px;
  margin-top: 20px;
  @media screen and (min-width: 320px) {
    font-size: 1.6em;
    line-height: 1.7em;
    width: 90%;
  }
  @media screen and (min-width: 425px) {
    font-size: 1.5em;
    line-height: 1.5em;
    width: 90%;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.6em;
    line-height: 1.5em;
    width: 90%;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.6em;
    line-height: 1.5em;
    width: 20em;
  }
  @media screen and (min-width: 1440px) {
    font-size: 1.8em;
    line-height: 2em;
    width: 90%;
  }
`

export default function MtmBenefits() {
  return (
    <AboutContainer id="benefits">
      <AboutWrapper>
        <Header>Our Story</Header>
        <Hr />
        <Text>
        Welcome to Afyabook, an online platform dedicated to revolutionizing healthcare in Kenya. We provide pharmacy services, medication therapy management, remote patient monitoring, and doctors consultations all in one place         
        </Text>
        <Text>
        Our founder Dr. Ibrahim Okich, a former hospital pharmacist with close to ten years of experience working in JCIA accredited hospitals, observed that the healthcare industry's challenges can be solved by smart information technology systems. Driven by a passion for IT, he pursued software development and data science, which led to the birth of Afyabook         
        </Text>
        <Text>
        Our primary goal is to improve equity in healthcare across Kenya, driven by the high cost of healthcare and the loss of loved ones to preventable diseases. Our founder's personal experience with the healthcare system and the loss of his mother to cancer inspired the creation of Afyabook         
        </Text>
        <Text>
        We believe that we can improve the patient experience, improve population health, reduce healthcare costs, and promote healthcare team well-being by leveraging technology to provide efficient, effective, and safe software solutions in collaboration with healthcare providers         
        </Text>
        <Text>
        At Afyabook, we are committed to providing accessible healthcare to all. We understand that healthcare costs are a burden, and we are here to alleviate that burden. We offer a one-stop-shop for all your healthcare needs, saving you time and money while ensuring the best possible care         
        </Text>
        <Text>
        Join us in our mission to improve the healthcare industry in Kenya. Whether you need medication therapy management, remote patient monitoring, or a doctor's consultation, we are here to provide you with the care you deserve. Together, we can make a difference in the lives of Kenyans, one patient at a time         
        </Text>
        <Hr />
      </AboutWrapper>
    </AboutContainer>
  )
}
