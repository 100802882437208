import React from "react"
import { MdInsights } from "react-icons/md"
import { FcCollaboration } from "react-icons/fc"
import { MdOutlineEscalatorWarning } from "react-icons/md"
import { AiOutlineRise } from "react-icons/ai"
import { AiFillBulb } from "react-icons/ai"
import { SiFuturelearn } from "react-icons/si"
import {
  Card,
  CardDesc,
  CardTitle,
  Container,
  Header,
  IconSpan,
  InnerCont,
  Slogan,
  Title,
  Wrapper,
} from "../provider-section/styles/whyUs"
const HeaderData = [
  {
    intro: "Core Values",
    subtitle: "",
  },
]
const SectionData = [
  {
    id: 1,
    icon: <MdInsights />,
    title: "Efficiency and creativity",
    desc: "We test various ideas and pick the most efficient",
  },
  {
    id: 2,
    icon: <FcCollaboration />,
    title: "Collaborative and partnership",
    desc: "We believe in coming together to solve challenges",
  },
  {
    id: 3,
    icon: <AiOutlineRise />,
    title: "Constant improvement",
    desc: "We focus on incremental improvement over time",
  },
  {
    id: 4,
    icon: <AiFillBulb />,
    title: "Innovative",
    desc: "We do our research to come up with innovative solutions",
  },
  {
    id: 5,
    icon: <MdOutlineEscalatorWarning />,
    title: "Leadership",
    desc: "We provide leadership in health information Technology",
  },
  {
    id: 6,
    icon: <SiFuturelearn />,
    title: "Learning",
    desc: "We learn constantly to keep abreast of the changing world",
  },
]

export default function whyUs() {
  return (
    <Container>
      <Wrapper>
        <Header>
          {HeaderData.map(item => {
            const { intro, subtitle } = item
            return (
              <>
                <Title>{intro}</Title>
                <Slogan>{subtitle}</Slogan>
              </>
            )
          })}
        </Header>
        <InnerCont>
          {SectionData.map(item => {
            const { id, title, desc, icon } = item
            return (
              <>
                <Card key={id}>
                  <IconSpan>{icon}</IconSpan>
                  <CardTitle>{title}</CardTitle>
                  <CardDesc>{desc}</CardDesc>
                </Card>
              </>
            )
          })}
        </InnerCont>
      </Wrapper>
    </Container>
  )
}
