import React from 'react';
import styled from 'styled-components';
import {
  CardWrapper,
  Desc,
  Heading,
  WorkflowCard,
  WorkflowInner,
  WorkflowSection,
  InnerContainer,
} from '../rmt-section/styles/rpmWorkflowElements';

const workflowData = [
  {
    title: 'Vision',
    text: 'Promote equitable healthcare access through cutting-edge technology to reduce costs',
  },
  {
    title: 'Mission',
    text: "Strengthen Kenya's health systems with intelligent software for better patient experience, population health, and cost reduction",
  },
];

function WorkFlow() {
  return (
    <WorkflowSection>
      <InnerContainer>
        <CardWrap>
          <CardWrapper>
            <Heading style={{ textAlign: 'center' }}>Who We Are</Heading>
            <Desc style={{ textAlign: 'center' }}>Innovative healthcare technology company for enhancing quality of life</Desc>
          </CardWrapper>
          <br />
          <br />
          <WorkflowInner>
            {workflowData.map((item, index) => (
              <WorkflowCard key={index}>
                <CardWrapper>
                  <Heading style={{ textAlign: 'center' }}>{item.title}</Heading>
                  <Desc style={{ textAlign: 'center', maxWidth: '600px' }}>{item.text}</Desc>
                </CardWrapper>
              </WorkflowCard>
            ))}
          </WorkflowInner>
        </CardWrap>

      </InnerContainer>
    </WorkflowSection>
  );
}

export default WorkFlow;

const CardWrap = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 1rem;
    align-items: center;
`;
