import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {
  BannerContainer,
  BannerWrapper,
  Heading,
  ImgCont2,
  ScrollerContainer,
  ScrollText,
  ScrollWheel,
  Subtitle,
  TextContainer,
} from '../service-section/styles/bannerElements';

export default function Banner() {
  return (
    <>
      <BannerContainer>
        <BannerWrapper>
          <TextContainer>
            <Heading>Revolutionizing Healthcare in Kenya</Heading>
            <Subtitle style={{ fontSize: '1rem' }}>
              Transforming healthcare with innovative technology solutions
            </Subtitle>
          </TextContainer>
          <ImgCont2>
            <StaticImage src="../../images/mtmHeroImg.webp" alt="About Afyabook" placeholder="blurred" imgStyle={{ borderRadius: '20px' }} />
          </ImgCont2>
        </BannerWrapper>
        <ScrollerContainer>
          <ScrollWheel />
        </ScrollerContainer>
      </BannerContainer>
      <ScrollText>Scroll down to learn more</ScrollText>
    </>
  );
}
