import * as React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { Link } from 'gatsby';
import NoResultsModal from './NoResultsModal';

function SearchElement() {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [value, delay]);

    return debouncedValue;
  }

  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        setProducts([]);
        const result = await axios.get(
          `${process.env.GATSBY_API_URL}/products?search=${debouncedSearch}`,
        );
        const productsData = result.data;

        setProducts(productsData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const { message } = err.response.data;
        setError(message);
      }
    }

    if (debouncedSearch) fetchData();
  }, [debouncedSearch]);

  const productApproved = products.filter((p) => p.showProduct === true || 'true');

  const productsArrayUniq = [
    ...new Set(productApproved.map((p) => p.productName)),
  ];

  const [isNoResultsModalOpen, setNoResultsModal] = useState(false);

  const toggleNoResultsModal = () => {
    setNoResultsModal(!isNoResultsModalOpen);
  };

  return (
    <Search>
      <NoResultsModal
        isModalOpen={isNoResultsModalOpen}
        closeModal={toggleNoResultsModal}
      />
      <input
        type="search"
        placeholder="search medication"
        onChange={(e) => setSearch(e.target.value)}
      />

      {search && (
        <Results>
          {loading && <p>Loading...</p>}
          {products.length > 0 ? (
            productsArrayUniq.map((product, index) => (
              <div key={index}>
                <Link
                  to={`/search/${product.replace(/ /g, '-').toLowerCase()}`}
                >
                  <p style={{ textDecoration: 'none' }}>
                    {product.charAt(0).toUpperCase()
                        + product.slice(1).toLowerCase()}
                  </p>
                </Link>
              </div>
            ))
          ) : (
            <>
              {!loading && <div>No results found</div>}
              {!loading && (
                <Button onClick={() => toggleNoResultsModal()}>
                  Find it for me
                </Button>
              )}
            </>
          )}
        </Results>
      )}
    </Search>
  );
}

export default SearchElement;

const Search = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  input {
    padding: 1rem 1rem;
    outline: none;
    width: 17rem;
    height: 40px;
    border-radius: 10px;
    border: 2px solid #71c4de;

    @media screen and (min-width: 400px) {
      width: 20rem;
    }

    @media screen and (min-width: 600px) {
      width: 30rem;
    }

    @media screen and (min-width: 800px) {
      width: 40rem;
    }
  }
`;

const Results = styled.div`
  overflow: auto;
  position: absolute;
  top: 3rem;
  background: white;

  padding: 1rem 1rem;
  outline: none;
  width: 17rem;
  height: 300px;
  border-radius: 10px;
  border: 2px solid #71c4de;

  @media screen and (min-width: 400px) {
    width: 20rem;
  }

  @media screen and (min-width: 600px) {
    width: 30rem;
  }

  @media screen and (min-width: 800px) {
    width: 40rem;
    height: 400px;
  }
`;
