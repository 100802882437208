import React from "react"
import BannerSection from "../sections/about/banner"
import WorkFlowSection from "../sections/about/workflow"
import BenefitsSection from "../sections/about/MtmBenefits"
import RpmWhyNowSection from "../sections/rmt-section/RpmWhyNow"
import RpmWhyUsSection from "../sections/about/RpmWhyUs"
import Footer from "../components/homeNav/footer/Footer"
import Seo from "../components/seo"
import BotNav from "../components/bottomNav"
import PageNav from "../components/homeNav/PageNav"

export default function service2() {
  return (
    <>
    <Seo
        title="Afyabook Healthcare platform | Afyabook"
        description="Healthcare at your finger tips"
        img="https://www.afyabook.com/static/bcbc5b3e1123cc303539aba298f1482f/66dad/banner-thumb.webp"
        keywords="Online pharmacy, medication therapy management, doctors consultation, remote patient monitoring"
        siteUrl="https://www.afyabook.com/aboutUs"
        canonical="https://www.afyabook.com/aboutUs"
      ></Seo>
      <PageNav />
      <BannerSection />
      <WorkFlowSection />
      <BenefitsSection />
      <RpmWhyNowSection />
      <RpmWhyUsSection />
      <BotNav />
      <Footer />
    </>
  )
}
