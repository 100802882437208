import React from "react";
import styled from "styled-components";
import {
  Nav,
  NavbarContainer,
  Counter,
  NavLogo,
  RegBoxin,
  MobileIcon,
  CartLink,
  AfyabookLogo,
} from "./NavBarElement";
import Logo from "../../images/Afyabook.svg";
import ProfileMenu from "../topNav/menuPopup/AccountMenu";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SearchElement from "./searchElement";
import useCartState from "../../stores/cart";
import { navigate } from 'gatsby';

const UploadButtonContainer = styled.div`
  display: none;

  @media (min-width: 768px) { 
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const UploadIconStyled = styled(UploadFileOutlinedIcon)`
  font-size: 2rem !important; 
  margin-left: 600px; 

  @media (min-width: 768px) and (max-width: 1024px) {
    font-size: 2rem !important; 
    margin-left: 200px; 
  }
`;

const FindDoctorButton = styled.button`
  padding: 5px 10px;
  font-size: 20px;
  background-color: #71C4DE; 
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; 
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
  }
`;

const Hoverfn = styled.div`
  position: relative;
  display: inline-block;  
  
  @media (min-width: 768px) { 
    &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  }
`;

const Tooltip = styled.span`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: 100%; /* Position above the icon */
  margin-top: 5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  transition: opacity 0.3s;
`;


const Navbar = ({ toggle }) => {
  const { getCartLength } = useCartState();

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <AfyabookLogo src={Logo} />
          </NavLogo>
          
          <UploadButtonContainer>
          <Hoverfn>
            <CartLink to="/upload-prescription">
              <UploadIconStyled />
              <Tooltip className="tooltip">Upload Prescription</Tooltip>
            </CartLink>
            </Hoverfn>
            <FindDoctorButton onClick={() => navigate(`/services/`)}>
              Lab Tests
            </FindDoctorButton>
            <FindDoctorButton onClick={() => navigate(`/doctors/`)}>
              Book Consultation
            </FindDoctorButton>
          </UploadButtonContainer>
          
          {/* <UploadButtonContainer>
            <FindDoctorButton onClick={() => navigate(`/doctors/`)}>
              Book Consultation
            </FindDoctorButton>
          </UploadButtonContainer> */}
          
          <RegBoxin>
            <CartLink to="/Cart">
              <ShoppingBagOutlinedIcon
                style={{ fontSize: "2.1rem", marginRight: "5px" }}
              />
              <Counter>{getCartLength()}</Counter>
            </CartLink>
          </RegBoxin>
          
          <MobileIcon>
            <ProfileMenu />
          </MobileIcon>
        </NavbarContainer>
      </Nav>
      
      <div style={{ position: "sticky", top: "60px", zIndex: "20" }}>
        <SearchElement />
      </div>
    </>
  );
};

export default Navbar;
